import { useEffect } from 'react';

import { useContentPageContext } from '@netfront/ekardo-content-library';
import { useAuthentication } from '@netfront/gelada-identity-library';
import { Button } from '@netfront/ui-library';

import { AppStoreLinks } from 'components/Social';

import { Affiliates, Page } from '../../../components';
import { useGetRegisterUrl } from '../../../hooks';

const LandingPage = () => {
  const { isAuthenticated } = useAuthentication();
  const { dispatch } = useContentPageContext();

  const { registerUrl: accessTheProgramUrl } = useGetRegisterUrl();

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    dispatch({
      type: 'unloadContentPage',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <Page
      hasPrivateLayout={false}
      meta={{
        seoDescription: 'A purpose built, moderated, and secure social network',
        seoTitle: 'Breathing space community',
      }}
      title="Breathing space community"
    >
      <section className="bg-gradient-angle">
        <div className="container py-12 md:py-16">
          <div className="flex flex-col-reverse lg:flex-row flex-wrap">
            <div className="w-full lg:w-1/2">
              <img alt="Background gradient" src="/images/breathing-space/breathingspace-banner.png" aria-hidden />
            </div>
            <div className="w-full lg:w-1/2">
              <div className="bg-green-300 rounded-lg p-10 lg;mt-12">
                <span className="h5 weight-800 block text-uppercase">Welcome</span>
                <h1 className="font-header weight-800 text-uppercase mb-6">SUPPORT FROM CLINICIANS AND EACH OTHER</h1>

                <AppStoreLinks />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container container-sm pt-12 pb-24 text-center lg:text-left">
          <div className="flex flex-wrap -mx-8">
            <div className="w-full lg:w-1/2 p-8">
              <span className="color-accent h5 weight-600 text-uppercase lg:mt-12 block">Features</span>
              <h2 className="text-uppercase mb-6">Learn more</h2>

              <div className="flex flex-col items-center lg:items-start">
                <div className="bg-white py-16 px-6 shadow rounded-lg inline-flex flex-col items-center text-center mb-4 max-w-72">
                  <img alt="Connect & relate with others" className="mb-6" src="/images/breathing-space/icon-connect-relate.svg" />
                  <span className="h5 weight-600 mb-0">CONNECT & RELATE WITH OTHERS</span>
                </div>

                <div className="bg-white py-16 px-6 shadow rounded-lg inline-flex flex-col items-center text-center mb-4 max-w-72">
                  <img alt="Share your thoughts & emotions" className="mb-6" src="/images/breathing-space/icon-connect-relate.svg" />
                  <span className="h5 weight-600 mb-0">SHARE YOUR THOUGHTS & EMOTIONS</span>
                </div>

                <div className="bg-white py-16 px-6 shadow rounded-lg inline-flex flex-col items-center text-center max-w-72">
                  <img alt="Connect with the community" className="mb-6" src="/images/breathing-space/icon-connect-relate.svg" />
                  <span className="h5 weight-600 mb-0">CONNECT WITH THE COMMUNITY</span>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2 p-8">
              <img alt="Mobile home screen" className="rounded-lg shadow max-w-72 lg:max-w-full" src="/images/breathing-space/feature-graphic.png" />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="container container-sm pt-28 pb-20">
          <span className="block color-primary h5 weight-600 text-uppercase">LEARN MORE</span>
          <h2 className="text-uppercase mb-6">
            WHAT IS BREATHING <br /> SPACE?
          </h2>
          <div className="flex flex-wrap -mx-6">
            <div className="w-full lg:w-1/2 px-6">
              <p>
                Breathing Space is a purpose built, moderated, and secure social network, where people can seek support from clinicians and
                each other to help them improve their wellbeing and resilience.
              </p>
            </div>
            <div className="w-full lg:w-1/2 px-6">
              <p>
                Breathing Space has been tested in clinical trials, and is effective in helping to improve mood, reduce feelings of
                isolation, and reduce problematic drinking.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gradient-angle-dark bg-white py-16 md:py-38">
        <div className="container container-sm text-center md:text-left">
          <div className="flex flex-col md:flex-row items-center justify-center mt-16">
            <img alt="Breathing space" className="mb-8 md:mb-0 md:mr-12 h-48" src="/images/breathing-space/logo-icon.svg" />
            <div>
              <span className="block color-white h5 weight-600 text-uppercase">Register</span>
              <h2 className="color-white text-uppercase mb-6">
                JOIN THE BREATHING <br />
                SPACE COMMUNITY
              </h2>
            
              <Button
                linkButton={{ id: 'regsiter-cta', url: accessTheProgramUrl }}
                text='Register'
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white py-26">
        <div className="container container-2xs text-center">
          <h2 className="mb-6 text-uppercase">Community page</h2>
          <p className="mb-8">
            Have you been referred to BreathingSpace? If so you can access community information on our communities page
          </p>

          <Button
            linkButton={{ id: 'communities', url: '/communities' }}
            text='Visit'
          />
        </div>
      </section>

      <Affiliates />
    </Page>
  );
};

// eslint-disable-next-line import/no-default-export
export { LandingPage };
