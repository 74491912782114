import { useEffect } from 'react';

import { ContentPageProvider, useGoogleAnalytics } from '@netfront/ekardo-content-library';
import { useUser } from '@netfront/gelada-identity-library';
import { Icons } from '@netfront/ui-library';
import { GoogleAnalytics, sendGAEvent } from '@next/third-parties/google';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { Toaster } from 'react-hot-toast';

import { PageView } from '../components';
import ErrorBoundary from '../components/Social/ErrorBoundary/ErrorBoundary';
import { SocialContextProvider } from '../contexts';
import { SessionProvider } from 'contexts/sessionProvider';

import '@egjs/flicking-plugins/dist/arrow.min.css';
import '@egjs/flicking-plugins/dist/pagination.min.css';
import '@egjs/react-flicking/dist/flicking.css';

// import '@netfront/ekardo-content-library/dist/index.css';
import '@netfront/ui-library/dist/esm/css/index.css';
import '@netfront/ui-library/dist/css/_datepicker.css';
import '@netfront/ui-library/dist/css/global.css';
import '@netfront/ui-library/dist/css/helpers.css';
import 'react-circular-progressbar/dist/styles.css';

import '../styles/cms.css';
import '../styles/cms/base.css';
import '../styles/cms/accordion.css';
import '../styles/cms/calendar.css';
import '../styles/cms/checkbox.css';
import '../styles/cms/custom.css';
import '../styles/cms/print.css';
import '../styles/cms/range.css';
import '../styles/cms/section.css';
import '../styles/cms/snippet.css';
import '../styles/cms/radio.css';
import '../styles/cms/table.css';
import '../styles/components.css';
import '../styles/globals.css';
import '../styles/theme.css';
import '../styles/utils.css';

function App({ Component, pageProps }: AppProps) {
  const { trackPageView, trackUser } = useGoogleAnalytics();
  const { events } = useRouter();
  const { getUser } = useUser();

  const user = getUser();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      PageView(url, Number(user?.id));

      sendGAEvent({
        event: 'routeChange',
        value: {
          url,
          userId: user?.id,
        },
      });
      
      trackPageView(url);
      trackUser();
    };
    // When the component is mounted, subscribe to router changes
    // and log those page views
    events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };
  }, [events, user]);

  return (
    <div id="root">
      <div>
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <Icons />
      <SessionProvider>
        <SocialContextProvider>
          <ContentPageProvider>
            {
              <ErrorBoundary>
                {/* // eslint-disable-next-line react/jsx-props-no-spreading */}
                <Component {...pageProps} />
                <GoogleAnalytics gaId={String(process.env.REACT_APP_GOOGLE_ANALYTICS)} />
              </ErrorBoundary>
            }
          </ContentPageProvider>
        </SocialContextProvider>
      </SessionProvider>
    </div>
  );
}

// eslint-disable-next-line import/no-default-export
export default App;
